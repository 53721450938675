import i18n from 'i18next';
import _ from 'lodash';
import { IAddOptions, Loader, LoaderResource } from 'pixi.js';

import type { ILoaderResource } from '@phoenix7dev/shared-components/dist/loader/d';
import type { IResource } from '@phoenix7dev/shared-components/dist/resources/d';

import { shouldDisplayPhoenix } from '../anticipation/utils';
import variables from '../assets/styles/export.module.scss';
import { SlotId, config } from '../config';
import { EventTypes, type ISettledBet } from '../global.d';
import {
  setBetAmount,
  setCoinAmount,
  setCoinValue,
  setCurrency,
  setGameMode,
  setSlotConfig,
  setStressful,
} from '../gql/cache';
import { MAX_WIN, eventManager } from '../slotMachine/config';
import type { Features } from '../slotMachine/d';

import { isFreeSpinMode } from './helper';

interface IPixiAssets {
  name: string;
  src: string;
  loadType?: LoaderResource.LOAD_TYPE;
}

export const wait = (ms: number): Promise<void> => {
  return new Promise((resolve) => {
    setTimeout(resolve, ms);
  });
};

export const pixiLoad = (): Promise<Partial<Record<string, LoaderResource>>> => {
  return new Promise((resolve, reject) => {
    Loader.shared.load((_loader, resources) => {
      const failed = _.filter(resources, (resource) => !!resource?.error);
      if (failed.length) return reject(failed);
      return resolve(resources);
    });
    Loader.shared.onError.once(() => {
      return reject();
    });
  });
};

export const loadPixiAssets = (assets: IPixiAssets[], baseUrl: string): Promise<void> => {
  Loader.shared.baseUrl = baseUrl;
  // eslint-disable-next-line no-async-promise-executor
  return new Promise(async (resolve, reject) => {
    assets.forEach((asset) => {
      const options: IAddOptions = {};
      if (asset.loadType) {
        options.loadType = asset.loadType;
        return Loader.shared.add(asset.name, asset.src, options);
      } else {
        return Loader.shared.add(asset.name, asset.src);
      }
    });
    let tries = config.failureRetries;
    let success = false;

    while (tries > 0) {
      try {
        tries -= 1;
        await pixiLoad();
        success = true;
        break;
      } catch (err) {
        console.error(err);
      }
    }

    return success ? resolve() : reject();
  });
};

export const loadImages = async (
  assets: IterableIterator<[string, IResource]>,
  cb?: CallableFunction,
): Promise<void> => {
  let promises: Promise<IResource>[] = [];
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  for (const [key, value] of assets) {
    promises.push(
      new Promise((resolve, reject) => {
        const asset: HTMLImageElement = new Image();
        asset.src = value.source;
        asset.onload = () => {
          if (cb) cb(value.key);
          resolve(value);
        };
        asset.onerror = () => reject(value);
      }),
    );
  }

  let tries = config.failureRetries;
  let success = false;

  while (tries > 0) {
    try {
      tries -= 1;
      const result = await Promise.allSettled(promises);
      const failed = _.filter(result, (asset) => asset.status === 'rejected') as PromiseRejectedResult[];

      if (failed.length) {
        promises = failed.map((rejected) => {
          return new Promise((resolve, reject) => {
            const asset: HTMLImageElement = new Image();
            asset.src = (rejected.reason as { source: string; key: string }).source as string;
            asset.onload = () => {
              if (cb) cb((rejected.reason as { source: string; key: string }).key);
              resolve(rejected.reason);
            };
            asset.onerror = () => reject(rejected.reason);
          });
        });
        continue;
      }
      success = true;
      break;
    } catch (err) {
      console.error(err);
    }
  }

  return success ? Promise.resolve() : Promise.reject();
};

export const isDevelopment = (): boolean => process.env.NODE_ENV === 'development';

export const isMobilePortrait = (width: number, height: number): boolean => {
  const isPortrait = height >= width;
  const maxWidth = parseInt(variables['breakpointMobilePortraitMax'] as string, 10);

  return isPortrait && width <= maxWidth;
};

export const isTesting = (): boolean => {
  return window.location.host.includes('testing');
};

export const isBuyFeatureEnabled = (features: Features[] = []): boolean => {
  const freeSpinFeature = features.find((i) => i.id === 'freeSpins');

  return freeSpinFeature?.enabled || false;
};

export const calcBottomContainerHeight = (width: number, height: number): number => {
  if (isMobilePortrait(width, height)) {
    return height * (parseInt(variables['bottomHeightPercentMobilePortrait'] as string, 10) / 100);
  }
  return height * (parseInt(variables['bottomHeightPercent'] as string, 10) / 100);
};

export const getFromMappedSymbol = <T>(map: Record<SlotId, { default: T; freespin?: T }>, id: SlotId): T =>
  isFreeSpinMode(setGameMode()) ? map[id as SlotId].freespin ?? map[id as SlotId].default : map[id as SlotId].default;

export const normalizeBalance = (balance = 0): number => {
  return balance / 100;
};

export const normalizeCoins = (coins = 0, coinValue = setCoinValue()): number => {
  return (coins * coinValue) / 100;
};

export const showCurrency = (currency: string): boolean => {
  return currency !== 'FUN';
};

export const generateMultiplierPositions = (
  x: number,
  y: number,
  equalParts: number,
  distance: number,
  padding: number,
  width: number,
  height: number,
): { x: number; y: number }[] => {
  const positions = [];
  const viewportWidth = width;
  const viewportHeight = height;

  if (equalParts <= 2) {
    const angleStep = (Math.PI * 2) / equalParts;
    for (let i = 0; i < equalParts; i++) {
      const angle = Math.random() * angleStep + i * angleStep;
      let posX = x + distance * Math.cos(angle);
      let posY = y + distance * Math.sin(angle);
      // Adjust position to stay inside the viewport
      posX = Math.max(padding, Math.min(viewportWidth - padding, posX));
      posY = Math.max(padding, Math.min(viewportHeight - padding, posY));
      positions.push({ x: posX, y: posY });
    }
  } else if (equalParts === 3) {
    // Triangle positions
    const triangleAngleStep = (Math.PI * 2) / 3;
    for (let i = 0; i < equalParts; i++) {
      const angle = i * triangleAngleStep;
      let posX = x + distance * Math.cos(angle);
      let posY = y + distance * Math.sin(angle);

      // Adjust position to stay inside the viewport
      posX = Math.max(padding, Math.min(viewportWidth - padding, posX));
      posY = Math.max(padding, Math.min(viewportHeight - padding, posY));

      positions.push({ x: posX, y: posY });
    }
  } else if (equalParts === 4) {
    // Rhombus positions
    const rhombusPositions = [
      { x: x + distance, y },
      { x, y: y + distance },
      { x: x - distance, y },
      { x, y: y - distance },
    ];
    const randomRotation = (Math.random() * Math.PI) / 2;
    for (let i = 0; i < rhombusPositions.length; i++) {
      const position = rhombusPositions[i as number];
      const offsetX = (position!.x - x) * Math.cos(randomRotation) - (position!.y - y) * Math.sin(randomRotation);
      const offsetY = (position!.x - x) * Math.sin(randomRotation) + (position!.y - y) * Math.cos(randomRotation);
      let posX = x + offsetX;
      let posY = y + offsetY;

      // Adjust position to stay inside the viewport
      posX = Math.max(padding, Math.min(viewportWidth - padding, posX));
      posY = Math.max(padding, Math.min(viewportHeight - padding, posY));

      positions.push({ x: posX, y: posY });
    }
  } else if (equalParts >= 5) {
    // Polygon positions
    const polygonAngleStep = (2 * Math.PI) / equalParts;
    const offsetAngle = equalParts % 2 === 0 ? Math.PI / equalParts : 0;
    for (let i = 0; i < equalParts; i++) {
      const angle = i * polygonAngleStep + offsetAngle;
      let posX = x + distance * Math.sin(angle);
      let posY = y + distance * Math.cos(angle);

      // Adjust position to stay inside the viewport
      posX = Math.max(padding, Math.min(viewportWidth - padding, posX));
      posY = Math.max(padding, Math.min(viewportHeight - padding, posY));

      positions.push({ x: posX, y: posY });
    }
  }

  return positions;
};

export const generateEqualRandom = (): boolean => {
  return Math.random() > 0.5;
};

export const checkPhoenixAnticipation = (placeBet: ISettledBet): boolean => {
  const showPhoenix = shouldDisplayPhoenix();
  const betAmount = normalizeCoins(setBetAmount());
  const normalizedWinAmount = normalizeCoins(placeBet.bet.result.winCoinAmount);
  if (normalizedWinAmount >= MAX_WIN * betAmount && showPhoenix) {
    return true;
  }
  return false;
};

export const loadErrorHandler = (error?: Error, resources?: ILoaderResource[]): void => {
  const stage = resources?.find((r) => !!r.error);
  const errorMsg = stage?.error as unknown as string;
  setStressful({
    show: true,
    type: 'network',
    message:
      (i18n.t([errorMsg === 'Failed to fetch' ? 'errors.UNKNOWN.NETWORK' : 'errors.UNKNOWN.UNKNOWN']) as string) ||
      (error as unknown as string),
  });
};

export const queryParams = new URLSearchParams(window.location.search);

export const findSubstituteCoinAmount = (requestedCoinAmount: number, coinAmounts: number[]): number => {
  for (let i = coinAmounts.length - 1; i >= 0; i--) {
    const coinAmount = coinAmounts[i]!;

    if (coinAmount <= requestedCoinAmount) {
      return coinAmount;
    }
  }

  return coinAmounts[0] ?? 0;
};

export const updateCoinValueAfterBonuses = (): void => {
  // updated coin value from BE after bonus game, because on bonus game we use Coin Value from history
  const coinValue = setSlotConfig().clientSettings.coinValues.find((elem) => elem.code === setCurrency())?.variants[0];
  const coinAmount = findSubstituteCoinAmount(setCoinAmount(), setSlotConfig().clientSettings.coinAmounts.default);
  setCoinValue(coinValue);
  setCoinAmount(coinAmount);
  setBetAmount(coinAmount * setSlotConfig().lineSets[0]!.coinAmountMultiplier);
  eventManager.emit(EventTypes.UPDATE_BET);
};
