import {
  ApolloClient,
  ApolloLink,
  GraphQLRequest,
  HttpLink,
  InMemoryCache,
  ServerParseError,
  from,
} from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { onError } from '@apollo/client/link/error';
import { RetryLink } from '@apollo/client/link/retry';

import { ApplicationError, EErrorCodeClient, EErrorCodeOperator, EErrorKind } from '@phoenix7dev/common-errors';

import { GameMode, IMetadata } from '../global.d';
import i18n from '../i18next';
import { Logic } from '../logic';
import { PopupTypes } from '../slotMachine/config';
import { PopupController } from '../slotMachine/popups/PopupController';
import { fallBackReelPosition, isBuyFeatureMode, isRegularMode } from '../utils';

import { setFreeRoundsBonus, setIsRevokeThrowingError, setReplayBet, setSlotConfig, setStressful } from './cache';
import { isStoppedGql } from './query';
import typePolicies from './typePolices';

const REST_URL = process.env['REACT_APP_URL'] as string;
const ERROR_CODES = [503, 502];
const { NETWORK_RETRY_ATTEMPTS = 5, NETWORK_RETRY_DELAY = 1000 } = window.__ENV__;
const RETRY_OPERATIONS = ['PlaceBet'];
const CSRF_HEADER = 'x-csrf-token';
let csrfToken = '';

const errorLink = onError(({ graphQLErrors, networkError, operation, forward }) => {
  const { retryCount } = operation.getContext();
  const statusCode = (networkError as ServerParseError)?.statusCode;
  if (RETRY_OPERATIONS.includes(operation.operationName) && ERROR_CODES.includes(statusCode)) {
    if (typeof retryCount === 'undefined' || retryCount < NETWORK_RETRY_ATTEMPTS) {
      forward(operation);
      return;
    }
  }

  if (setIsRevokeThrowingError()) return;
  if (graphQLErrors) {
    setIsRevokeThrowingError(true);
    if (isRegularMode(Logic.the.controller.gameMode) || isBuyFeatureMode(Logic.the.controller.gameMode)) {
      fallBackReelPosition();
    }
    // eslint-disable-next-line no-restricted-syntax
    for (const err of graphQLErrors) {
      const { extensions } = err;
      const metadata = extensions?.['data'] as IMetadata | undefined;
      const messageTranslate =
        metadata?.data?.messageBody || [extensions?.['i18nKey'] as string, 'errors.UNKNOWN.UNKNOWN'].filter(Boolean);
      const message = i18n.t(messageTranslate, metadata);
      const title = i18n.t(metadata?.data?.messageTitle || '', metadata);
      setIsRevokeThrowingError(true);
      const e = ApplicationError.getShapeByAppCode(extensions?.['applicationCode'] as number);
      if (e.kind === EErrorKind.CLIENT) {
        if (e.code === EErrorCodeClient.INSUFFICIENT_FUNDS) {
          setStressful({
            show: true,
            type: 'balance',
            message,
            title,
          });
          return;
        }
      }
      if (e.kind === EErrorKind.OPERATOR) {
        if (
          e.code === EErrorCodeOperator.INVALID_BONUS &&
          Logic.the.controller.gameMode === GameMode.FREE_ROUND_BONUS
        ) {
          setStressful({
            show: true,
            type: 'network',
            message,
            title,
            callback: () => {
              setFreeRoundsBonus({ ...setFreeRoundsBonus(), isActive: false });
              PopupController.the.openPopup(PopupTypes.FREE_ROUNDS_END, { isExpired: true });
            },
          });
          return;
        }
      }

      setStressful({
        show: true,
        type: metadata?.type === 'BLOCKING' ? 'blocking' : 'network',
        message,
        title,
      });
    }
  } else if (networkError) {
    setIsRevokeThrowingError(true);
    if (isRegularMode(Logic.the.controller.gameMode) || isBuyFeatureMode(Logic.the.controller.gameMode)) {
      fallBackReelPosition();
    }
    setStressful({
      show: true,
      type: 'network',
      message: i18n.t('errors.UNKNOWN.NETWORK'),
    });
  } else {
    setIsRevokeThrowingError(true);
    if (isRegularMode(Logic.the.controller.gameMode) || isBuyFeatureMode(Logic.the.controller.gameMode)) {
      fallBackReelPosition();
    }
    setStressful({
      show: true,
      type: 'network',
      message: i18n.t('errors.UNKNOWN.UNKNOWN'),
    });
  }
});

const connectionParams = (operationName: string | undefined) => {
  const { sessionId } = setSlotConfig();
  if (
    setReplayBet() &&
    (operationName === 'replayBet' ||
      operationName === 'replaySlotHistory' ||
      operationName === 'getReplayUserBonuses' ||
      operationName === 'replayGetUser' ||
      operationName === 'replayBonusBets')
  ) {
    return {
      'x-replay-token': 'af01adc0-071d-4a62-a191-abd62fc98e05',
      'x-bet-id': setReplayBet(),
    };
  }

  return {
    Authorization: sessionId,
  };
};

const authLink = setContext((gqlRequest: GraphQLRequest) => {
  return {
    headers: {
      ...connectionParams(gqlRequest.operationName),
    },
  };
});

const httpLink = new HttpLink({
  uri: REST_URL,
});

const retryLink = new RetryLink({
  delay: {
    initial: NETWORK_RETRY_DELAY,
    max: NETWORK_RETRY_DELAY,
    jitter: true,
  },
  attempts: (count, operation, error) => {
    const status = error?.networkError?.statusCode || error?.statusCode;
    const { operationName } = operation;
    if (count <= NETWORK_RETRY_ATTEMPTS) {
      operation.setContext((context: Record<string, unknown>) => ({ ...context, retryCount: count }));
      return RETRY_OPERATIONS.includes(operationName) && ERROR_CODES.includes(status);
    }
    return false;
  },
});

const readSecurityLink = new ApolloLink((operation, forward) => {
  return forward(operation).map((response) => {
    const context = operation.getContext();
    const headers = context['response']?.headers;

    if (headers.get(CSRF_HEADER)) {
      csrfToken = headers.get(CSRF_HEADER);
    }

    return response;
  });
});

const writeSecurityLink = new ApolloLink((operation, forward) => {
  if (csrfToken) {
    operation.setContext(({ headers = {} }) => ({
      headers: {
        ...headers,
        [CSRF_HEADER]: csrfToken,
      },
    }));
  }
  return forward(operation);
});

const cache = new InMemoryCache({
  typePolicies,
});

cache.writeQuery({
  query: isStoppedGql,
  data: {
    isSlotStopped: true,
  },
});

const client = new ApolloClient({
  link: authLink.concat(from([readSecurityLink, writeSecurityLink, retryLink, errorLink, httpLink])),
  cache,
});

export default client;
